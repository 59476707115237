import axios from "axios";
import React, { useEffect, useState } from "react";
import StakingCalculatorPage from "./staking-calculator";

const Home = () => {
  let urls = [
    "https://explorer.hydrachain.org/api/info",
    "https://api.coingecko.com/api/v3/simple/price?ids=hydra&vs_currencies=usd",
    "https://api.coingecko.com/api/v3/simple/price?ids=lockchain&vs_currencies=usd",
    "https://hydra.locktrip.com/nodes/available",
  ];

  const [data, setData] = useState({
    loading: true,
    totalAssetsStaked: 0,
    capitalRequiredForAttack: 0,
    apy: 0,
    blocksMined: 0,
    stakingRewardsPaidOut: 0,
    totalValueOfAssetsOnChain: 0,
    nodes: [],
  });

  const getData = async () => {
    let promises = urls.map((url) => {
      return new Promise((resolve, reject) => {
        (async () => {
          try {
            let { data } = await axios.get(url);
            resolve(data);
          } catch (e) {
            reject({ error: true });
          }
        })();
      }).catch(function (err) {});
    });

    Promise.all(promises)
      .then(function (res) {
        const [hydraInfo, hydraPrice, locPrice, nodes] = res;
        const netStakedWeight =
          ((hydraInfo?.circulatingSupply || 0) - 560000) * 0.94;
        const totalAssetsStaked =
          (netStakedWeight * (hydraPrice?.hydra?.usd || 0)) / 1000000;
        const capitalRequiredForAttack = totalAssetsStaked * 0.51;
        const apy =
          ((parseFloat(hydraInfo?.supply || 0) * 0.2) / netStakedWeight) * 100;

        const blocksMined = hydraInfo?.height || 0;
        const stakingRewardsPaidOut =
          (((hydraInfo?.supply || 0) - 18500000) *
            (hydraPrice?.hydra?.usd || 0)) /
          1000000;
        const valueOfHydraOnChain =
          (hydraInfo?.circulatingSupply || 0) * (hydraPrice?.hydra?.usd || 0);
        const valueOfLOCOnChain = 18500000 * (locPrice?.lockchain?.usd || 0);
        const totalValueOfAssetsOnChain =
          (valueOfHydraOnChain + valueOfLOCOnChain) / 1000000;
        setData({
          loading: false,
          totalAssetsStaked,
          capitalRequiredForAttack,
          apy,
          blocksMined,
          stakingRewardsPaidOut,
          totalValueOfAssetsOnChain,
          nodes,
        });
      })
      .catch(function (err) {});
  };

  useEffect(() => {
    getData();
  }, []);

  const {
    loading,
    totalAssetsStaked,
    capitalRequiredForAttack,
    apy,
    blocksMined,
    stakingRewardsPaidOut,
    totalValueOfAssetsOnChain,
    nodes,
  } = data;

  return <StakingCalculatorPage />;
};

export default Home;
